//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions} from "vuex";

export default {
  name: "ChangePasswordCard",
  props: {
    userId: 0,
    userName: ''
  },
  data: (vm) => ({
      changePasswordForm: {
        data: {
          newPassword: '',
          confirmPassword: '',
        },
        rules: [(v) => !!v || 'Required'],
        newPasswordRules: [
          (v) => !!v || 'Required',
        ],
        confirmPasswordRules: [
          (v) => !!v || 'Required',
          (v) =>
            (!!v && v === vm.changePasswordForm.data.newPassword) ||
            'Passwords must match',
        ],
        valid: false,
      },

  }),
  methods: {
    ...mapActions('common/userProfile', [
      'changePassword',
    ]),
    async save() {
      if (
        this.changePasswordForm.valid &&
        this.changePasswordForm.data.newPassword
      ) {
        this.changePasswordForm.data.userId = this.userId
        const response = await this.$axios.$post('/User/must-change-password', this.changePasswordForm.data)

        if (
          response &&
          response.apiMessages.hasErrors &&
          response.apiMessages.validationErrors
        ) {
          this.$toast.error(response.apiMessages.validationErrors.error)
        } else if (response) {
          this.$toast.success('Password successfully changed!')
          const responseLogin = await this.$auth.loginWith('local', {
            data: {
              username: this.userName,
              password: this.changePasswordForm.data.newPassword,
            },
          })
          if (responseLogin.status === 200 && responseLogin.data.token) {
            this.$toast.success('Logged in!')
            this.$emit('loggedIn')
          } else {
            this.$toast.error('Invalid username or password!')
          }
        }
      } else {
        this.validate()
      }
    },
    validate() {
      this.$refs.changePasswordForm.validate()
    },
  }
}
