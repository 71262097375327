export const availableModules = {
  Dashboard: 'Dashboard',
  Estimate: 'Estimate',
  CustomOrder: 'Custom order',
  Catalog: 'Catalog',
  Quotes: 'Quotes',
  JobOrders: 'Job orders',
  SalesOrders: 'Sales orders',
  Invoices: 'Invoices',
  Payments: 'Payments',
  CustomerCredits: 'Customer credits',
  Configuration: 'Configuration',
}
