//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SecondaryButton from '@/components/shared/secondary-button'
import {mapActions} from 'vuex'
export default {
  name: 'ForgotPasswordDialog',
  components: {SecondaryButton},
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data: (vm) => {
    return {
      forgotPasswordForm: {
        username: '',
      },
      valid: true,
    }
  },
  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    ...mapActions('common/gridSetting', ['saveGridSetting']),
    close() {
      this.$emit('close', false)
    },
    async resetPassword() {
      // captcha
      if(this.$refs.forgetPasswordForm && this.$refs.forgetPasswordForm.validate()) {
        let captchaResult = await this.checkIfUserIsHuman('forgotPassword')
        console.log(captchaResult)
        if(!captchaResult.success || captchaResult.score < 0.5) {
          return
        }
      }


      if (
        this.$refs.forgetPasswordForm &&
        this.$refs.forgetPasswordForm.validate()
      ) {
        try {
          const response = await this.$axios.post('auth/forgotPassword', {
            Username: this.forgotPasswordForm.username,
          })

          if (response.status === 200) {
            if (response.data.apiMessages.hasErrors) {
              this.$toast.error(
                response.data.apiMessages.serverErrors.join(' \n')
              )
            } else {
              this.$toast.success(
                response.data.apiMessages.successMessages.join(' \n')
              )
            }
          } else {
            this.$toast.error(
              'There was an error resetting the password for the user! Error code: ' +
                response.status
            )
          }
        } catch (err) {
          this.$toast.error(
            'There was an error resetting the password for the user!'
          )
        }
      }
    },
    async checkIfUserIsHuman(actionType) {
      let googleResult = ''
      try {
        const token = await this.$recaptcha.execute(actionType)
        await this.$axios
          .$get(`/recaptcha-handler`, {
            params: {
              googleToken: token,
            },
          }).then(res=>{
          googleResult = JSON.parse(res)
        })
      }
      catch (e) {
        console.log(e)
      }
      return googleResult;
    },
  },
  beforeDestroy() {
    this.$recaptcha.destroy()
  }
}
