//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LabeledControlLayout from '@/components/shared/labeled-control-layout'
import ForgotPasswordDialog from '@/components/common/forgot-password-dialog'
import {mapActions, mapGetters} from 'vuex'
import {adminCodes} from '@/enums/adminSettings'

export default {
  name: 'LoginCard',
  components: {
    LabeledControlLayout,
    ForgotPasswordDialog,
  },
  data() {
    return {
      forgotPasswordDialog: false,
      createNewCustomer: true,
      signInForm: {
        data: {
          email: '',
          password: '',
        },
        emailRules: [
          (v) => !!v || 'Required',
          (v) =>
            /.+@.+\..+/.test(v) ||
            this.$t('Please enter a valid email address'),
        ],
        rules: {
          required: (value) => !!value || 'Required',
          min: (v) => (v && v.length >= 8) || 'Min 8 characters',
        },
        rememberMe: false,
        showPassword: false,
        valid: false,
      },
      loginContantInfo: {
        phone_number: '531-005-555',
        email_address: 'fusion@gopiro.com',
        contact_text: '',
        contact_text2:
          '<div> Are you already a customer but do not have portal access yet? <br/> Contact us to activate your account: </div>',
        footer: '',
      },
      hideSignUpSection: true,
    }
  },
  computed: {
    ...mapGetters('rememberedUser',['getSavedUserInfo']),
  },
  methods: {
    ...mapActions('backoffice/settings', ['fetchAdminSettingByCode']),
    ...mapActions('rememberedUser', ['saveUser','removeSavedUser']),
    async signIn() {
      if (this.$refs.signInForm.validate()) {
        try {
          const response = await this.$axios.$post(
            `/auth/portal-login-check-must-change-password`,
            {
              username: this.signInForm.data.email,
              password: this.signInForm.data.password,
            }
          )
          if (response.hasErrors) {
            response.errorMessages.map((error, index) => {
              this.$toast.error(error.value)
            })
          } else if (response.value.mustChangePasswordOnNextLogin === true) {
            this.$emit(
              'mustChangePassword',
              response.value.id,
              response.value.userName
            )
          } else {
            const response = await this.$auth.loginWith('local', {
              data: {
                username: this.signInForm.data.email,
                password: this.signInForm.data.password,
              },
            })
            if (response.status === 200 && response.data.token) {
              if(this.signInForm.rememberMe) {
                this.saveUser({
                  email: this.signInForm.data.email,
                  password: this.signInForm.data.password,
                }
              )}
              else this.removeSavedUser()

              this.$toast.success('Logged in!')
              this.$emit('loggedIn')
            } else {
              this.$toast.error('Invalid username or password!')
            }
          }
        } catch (err) {
          console.log(err)
          this.$toast.error('Invalid username or password!')
        }
      }
    },
    signUp() {
      this.$emit('signUpHandler')
    },
    showDialog() {
      this.forgotPasswordDialog = true
    },
    doResetPassword() {
      // todo
    },
  },
  async mounted() {
    const savedUserInfo = this.getSavedUserInfo
    if (savedUserInfo) {
      this.signInForm.data.email = savedUserInfo.email
      this.signInForm.data.password = savedUserInfo.password
      this.signInForm.rememberMe = true
    }
    const contentData = (
      await this.$axios.get(`/contents/login-content-info`, {
        // /catch
        params: {version: 'published'},
      })
    ).data

    if (contentData.id) {
      this.loginContantInfo.email_address =
        contentData.details.email_address.fieldValue.value
      this.loginContantInfo.phone_number =
        contentData.details.phone_number.fieldValue.value
      if (
        contentData.details.contact_text &&
        contentData.details.contact_text.fieldValue.value
      )
        this.loginContantInfo.contact_text =
          contentData.details.contact_text.fieldValue.value

      if (
        contentData.details.contact_text2 &&
        contentData.details.contact_text2.fieldValue.value
      )
        this.loginContantInfo.contact_text2 =
          contentData.details.contact_text2.fieldValue.value
      if (
        contentData.details.footer &&
        contentData.details.footer.fieldValue.value
      )
        this.loginContantInfo.footer =
          contentData.details.footer.fieldValue?.value
    }

    const createNewCustomerSetting = await this.fetchAdminSettingByCode(
      adminCodes.CREATE_CUSTOMER_FOR_NEW_REGISTRATION
    )
    this.createNewCustomer =
      createNewCustomerSetting && createNewCustomerSetting.value === 'true'
    this.hideSignUpSection = (await this.fetchAdminSettingByCode(adminCodes.HIDE_SIGN_UP_SECTION_ON_THE_BACKOFFICE_HOMEPAGE))?.value === "true"
  },
}
